import React, {useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import example from '../../../img/services/carousel/tmp.jpg';

const Wrapper = styled.div`
  
  width: 100%;

  .pad-me {
    width: calc(100% - 80px);
    padding-right: 40px;
    padding-left: 40px;
  }

  h1 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    line-height: 52px;
    text-transform: uppercase;
    color: #585858;
    text-align: center;
  }
  .footer {
    min-height: 48px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #01426A;
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    b {
      color: #41B6E6;
      font-weight:bold;
    }
    &.show {
      opacity: 1;
      display: block;
    }
    &.hide {
      opacity: 0;
      display: none;
    }
  }

  .inner {
    /* max-width: 1134px; */
    max-width: 1386px;
    width: 100%;
    margin: 0 auto;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 20px;
  }

 
  .bg-lines {
    border: 2px solid #41B6E6;
    position: absolute;
    top: 0;
    z-index: 0;
    left: 0;
    width: calc(100% - 4px - 20%);
    margin-left: 10%;
    margin-right: 10%;
    height: 400px;
    transform: scaleY(.4);
    margin-top: 18px;
    @media (max-width: 1260px) {
      width: calc(100% - 4px - 4%);
      margin-left: 2%;
      margin-right: 2%;
    }
  }
  

  .slide {
    position: absolute;
    left: 50%;
    opacity: 1;
    transition: transform 500ms ease-in-out;
    box-shadow: 0 0 20px -5px black;
    img {
      width: 100%;
      display: block;
    }
  }

`;

function Carousel(props) {
  const ref = useRef();
  
  const testImages = [
    { image: {publicURL : example}},
    { image: {publicURL : example}},
    { image: {publicURL : example}},
    { image: {publicURL : example}},
    { image: {publicURL : example}},
    { image: {publicURL : example}},
    { image: {publicURL : example}},
  ];
  const defaultBreakpoints = {
    // min-width : {height: 400, centerImageWidth: 400 },
    1020: {height: 400, centerImageWidth: 400 },
    800: {height: 350, centerImageWidth: 350 },
    600: {height: 300, centerImageWidth: 300 },
    0: {height: 200, centerImageWidth: 200 },
  };
  const centerImageWidth = props.centerImageWidth ? props.centerImageWidth : 400;
  const height = props.height ? props.height : 400;
  const images = props.images ? props.images : testImages;
  const breakpoints = props.breakpoints ? props.breakpoints : defaultBreakpoints;

  let [bgLineStyle, setBgLineStyle] = useState({transform: 'scaleY(.4)'});
  let [imagesRef, setImages] = useState(images);
  let [config, setConfig] = useState({height: height, centerImageWidth: centerImageWidth });

  const setup = (centerImageNo = 1) => {
    // let centerImageNo = 1; //Math.ceil(images.length / 2);
    if(!ref.current || !ref.current.children) {
      return;
    }
    Object.keys(ref.current.children).forEach( (item, key) => {
      // console.log('item', ref.current.children[key].className);
      if(ref.current.children[key].className != 'slide') {
        return;
      }
      images[key].ref = ref.current.children[key];
      images[key].no  = key + 1;
    });

    // transform: scaleY(.4);
    // console.log('loaded', images);
    setPositions(centerImageNo);
  };
  const nextSlide = () => {
    let found = images.find( (row) => { return row.step == 0; });
    // console.log('nextSlide', found);
    if(found) {
      let centerImageNo = found.no + 1;
      if(centerImageNo > images.length) {
        centerImageNo = 1;
      }
      // let centerImageNo = found.no - 1;
      // if(centerImageNo < 1) {
      //   centerImageNo = images.length;
      // }
      setPositions(centerImageNo);
    }
  };
  const reset = () => {
    let found = images.find( (row) => { return row.step == 0; });
    if(found) {
      setup(found.no);
    } else {
      setup();
    }
  };
  const getScale = (step) => {
    return ( 1 - ( step * .2) );
  };
  const setPositions = (centerImageNo) => {

    images.forEach( (item, key) => {

      let scale = 0;
      let possitionOffset = 0;
      if( centerImageNo == item.no) {
        item.step = 0;
        item.side = 'center';
        item.position = 1;

        scale = 1;
        possitionOffset = 0;
      } else {

        let step1 = Math.abs(item.no - centerImageNo);
        let step2 = 0;
        if(item.no < centerImageNo) {
          step2 = Math.abs(centerImageNo - (item.no + images.length) );
          // console.log('less then', item.no,centerImageNo, step2);
        } else {
          step2 = Math.abs(centerImageNo - (item.no - images.length) );
          // console.log('greater then', item.no,centerImageNo, step2);
        }
        
        if(step1 < step2) {
          item.step = step1;
          scale = getScale(item.step);
        } else {
          item.step = step2;
          scale = getScale(item.step);
        }

        if(centerImageNo < item.no) {
          item.position = item.no - centerImageNo + 1;
        } else {
          item.position = images.length - centerImageNo + item.no + 1;
        }

        possitionOffset = 0;
        for(let x = 0; x < item.step; x++) {
          possitionOffset += config.centerImageWidth * getScale(x) *  .5;
        }
        
        if(item.position <= Math.ceil(images.length / 2) ) {
          item.side = 'right';
        } else {
          item.side = 'left';
          possitionOffset = - (possitionOffset);
        }
      }
      // console.log(item.no, item.position, item.side);

      item.zIndex = 100 - item.step;
      item.transform = `translate3d(${possitionOffset}px, 0px, 0px) scale(${scale})`;
      item.opacity = 100 - (item.step * 10);

      item.ref.style.zIndex = item.zIndex;
      item.ref.style.transform = item.transform;
      item.ref.style.opacity = item.opacity;
    });

    setImages([...images]);
    // console.log('set positions', images);
  };



  const slideClick = (slideNo) => {

    //open if image is link
    images.forEach( (item, key) => {
      if(item.hrefBlank && item.side == 'center' && item.no == slideNo) {
        window.open(item.hrefBlank, '_blank');
        return;
      }
    });
    turnOff(7);
    setPositions(slideNo);
  };

  function updateConfig() {
    const vw = window.innerWidth;
    let newConfig = null;
    let newMinWidth = null;
    Object.keys(breakpoints).forEach( (minWidth) => {
      if(vw > minWidth) {
        newConfig = breakpoints[minWidth];
        newMinWidth = minWidth;
      }
    });
    // console.log(newMinWidth, newConfig);
    config = newConfig;
    setConfig(newConfig);
    setBgLineStyle({
      transform: 'scaleY('+ getScale(Math.floor(images.length / 2) + 1) +')',
      height: config.height,
    });
    reset();
  }


  const turnOn = () => {
    window.carouselNextInterval = setInterval( () => {
      nextSlide();
    }, 3000);
  };
  const turnOff = (pauseSeconds = null) => {
    clearInterval(window.carouselNextInterval);

    if(pauseSeconds) {
      clearTimeout(window.carouselClickTimeout);
      window.carouselClickTimeout = setTimeout( () => {
        turnOn();
      }, 7 * 1000);
    }
    
  };

  useEffect( () => {
    updateConfig();
    turnOn();
    return () => {
      turnOff();
    };
  }, []);

  useEffect(() => {
    
    window.addEventListener('resize', debounce(updateConfig, 300));    
    updateConfig();
    return () => {
      window.removeEventListener('resize', updateConfig);
    };
  }, []);
  return (
    <Wrapper>
      {props.title && <h1 className="pad-me">{props.title}</h1> }
      <div className="inner" style={{height: config.height}} ref={ref}>
        {images.map( (row, index) => (
          <span 
            className="slide" 
            key={index} 
            style={{width: `${config.centerImageWidth}px`, marginLeft: `-${config.centerImageWidth/2}px`}} 
            onClick={() => slideClick(row.no) }
          >
            <img src={row.image.publicURL} />
            {/* <span className="num" style={{position: 'absolute', top: '50%', width: '100%', textAlign:'center', left: 0, fontSize: '30px' }}>{index + 1}</span> */}
          </span>
        ))}
        <div className="bg-lines" key={'bg-lines'} style={bgLineStyle}></div>
      </div>
      {imagesRef && imagesRef.map( (row, index) => (
        <div key={index} >
          {(imagesRef[0].html !== undefined && imagesRef[0].html !== false) &&
            <div className={`footer pad-me ${row.side == 'center' ? 'show' : 'hide'}`} dangerouslySetInnerHTML={{ __html: row.html ? row.html : '' }}></div>
          } 
        </div>
      ))}
      {/* <a className="button" style={{backgroundColor: 'black', margin:'0 auto', display: 'block'}} onClick={() => {nextSlide()}}>Test</a> */}
      { props.footerHtml && <div className="footer pad-me" dangerouslySetInnerHTML={{ __html: props.footerHtml }} /> }
      
    </Wrapper>
  );
}

export default Carousel;
